import { Component, OnInit } from '@angular/core';
import { ToastrService } from 'ngx-toastr';
import { Subject } from 'rxjs';
import { IFonctionnalite } from 'src/app/interface/fonctionnalite.interface';
import { FonctionnaliteService } from 'src/app/services/fonctionnalite/fonctionnalite.service';
import { UtilitiesService } from 'src/app/services/utilities/utilities.service';

@Component({
  selector: 'app-fonctionnalite',
  templateUrl: './fonctionnalite.component.html',
  styleUrls: ['./fonctionnalite.component.scss']
})
export class FonctionnaliteComponent implements OnInit {

  fonctionnalites: IFonctionnalite[] = []
  fonctionnaliteDtOptions: any = {};
  fonctionnaliteDtTrigger: Subject<any> = new Subject<any>();
  datatableIsInitialized: boolean = false;
  constructor(
    private fonctionnaliteService: FonctionnaliteService,
    private utilityService: UtilitiesService,
    private toastr: ToastrService
  ) { }

  ngOnInit(): void {
    this.getFonctionnalites()
    this.initDtOptions()

  }
  initDtOptions() {
    this.fonctionnaliteDtOptions = {
      pagingType: 'full_numbers',
      pageLength: 10,
      search: true,
      paging: true,
      language: {
        url: '/assets/i18n/datatable.fr.json',
        search: 'Rechercher'
      }
    };
  }
  getFonctionnalites() {
    const getFonctionnalitesRequest = this.fonctionnaliteService.get().subscribe({
      next: (response) => {
        if (response && !response.hasError) {
          this.fonctionnalites = response.items
          if (!this.datatableIsInitialized) {
            this.fonctionnaliteDtTrigger.next(this.fonctionnaliteDtOptions)
            this.datatableIsInitialized = true
          }
        } else {
          this.toastr.error('Oops! Nous ne parvenons pas à récupérer les données', 'Information');
        }
      },
      error: (err) => {
        this.utilityService.handleHTTPError(err)
      }
    })
  }

}
