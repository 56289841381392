<div class="container">
    <div class="row">
        <div class="col-md-6">
            <h1>Fonctionnalités</h1>
        </div>
    </div>
    <ng-container *ngxPermissionsOnly="'VIEW_PAGE_FONCTIONNALITE_LIST'">
    <div class="row">
        <div class="col-12 mt-3">
            <div class="card uv-stock-card">
                <div class="card-header bg-transparent d-flex justify-content-between">
                  <h5 class="text-body mb-0">Liste des fonctionnalités</h5>
                </div>
                <div class="card-body">
                    <div class="custom-table">
                        <table datatable [dtOptions]="fonctionnaliteDtOptions" [dtTrigger]="fonctionnaliteDtTrigger" class="table table-striped">
                            <thead>
                              <tr>
                                <th scope="col">Code</th>
                                <th scope="col">Libellé</th>
                              </tr>
                            </thead>
                            <tbody>
                              <tr *ngFor="let fonctionnalite of fonctionnalites">
                                <td>{{ fonctionnalite.code }}</td>
                                <td>{{ fonctionnalite.libelle }}</td>
                              </tr>
                            </tbody>
                            <tbody *ngIf="fonctionnalites.length === 0">
                              <tr><td colspan="2" class="text-center">Aucune donnée trouvée</td></tr>
                            </tbody>
                        </table>
                    </div>
                </div>
            </div>
        </div>
    </div>
    </ng-container>
</div>
<swal
  #deleteSwal
  title="Voulez-vous vraiment supprimer cette donnée ?"
  text="Cette action est irreversible"
  icon="question"
  [showCancelButton]="true"
  confirmButtonText="Oui, Supprimer"
  cancelButtonText="Annuler"
  [focusCancel]="true">
</swal>